import React, { useEffect, useState } from "react";
import LayoutWithImage from "../Layout/LayoutWithImage";
import { Button, Input, Typography } from "src/components";
import { Container, Form, Hint, SkipButton } from "./styles";
import "src/components/Input/phone_input.css";
import {
  isEmailValid,
  isPhoneNumberValid,
} from "src/components/Input/utils/validators";
import { useGetOnboardingNextStep } from "../../utils/useOnboardingSteps";
import {
  useAddPartnerMutation,
  useGetMemberQuery,
  useUpdateOnboardingStatusMutation,
} from "src/api/main";
import useUpdateStatus from "../../utils/useUpdateStatus";
import { useGetOnboardingStatusQueryResolver } from "src/api/resolvers";
import { isMobile } from "src/utils/isMobile";
import { useFlags } from "launchdarkly-react-client-sdk";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import { track } from "src/utils/track";
import RitualAnalytics from "client-analytics";

const fields = [
  { placeholder: "First name", id: "first_name" },
  { placeholder: "Last name", id: "last_name" },
  { placeholder: "Phone number", id: "phone_number", type: "tel" },
  { placeholder: "Email address", id: "email", type: "email" },
];

const AddPartnerStep = () => {
  const redirectToNextStep = useGetOnboardingNextStep();
  const [addPartner] = useAddPartnerMutation();
  const { addPartnerFromApp } = useFlags();
  const { data: member } = useGetMemberQuery();
  const updateOnboardingStatus = useUpdateStatus();
  const [updateOnboardingStatusMutation] = useUpdateOnboardingStatusMutation();
  const { data: onboardingStatus } = useGetOnboardingStatusQueryResolver();
  const isMatchingCategory = useIsMatchingPlanCategory();
  const eventPayload = {
    name: member?.full_name,
    email: member?.email,
  };

  useEffect(() => {
    if (
      onboardingStatus?.partner_added ||
      onboardingStatus?.partner_added_skipped
    ) {
      redirectToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStatus]);

  useEffect(() => {
    RitualAnalytics.track("invite_partner_page_open", eventPayload);
  }, []);

  const [values, setValues] = useState<{ [key: string]: string }>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const [error, setError] = useState<{ [key: string]: string }>({
    email: "",
    phone_number: "",
  });

  const validate = () => {
    setError({ email: "", phone_number: "" });
    let valid = true;
    if (!isEmailValid(values.email)) {
      setError((prev) => ({ ...prev, email: "Email is not valid" }));
      valid = false;
    }

    if (!isPhoneNumberValid(values.phone_number)) {
      setError((prev) => ({
        ...prev,
        phone_number: "Phone number is not valid",
      }));
      valid = false;
    }

    if (member?.phone_number === values.phone_number) {
      track("partnerdetail_duplicate_rejected", {
        rejectionsource: "phone_number",
      });
      setError((prev) => ({
        ...prev,
        phone_number:
          "Please use separate phone number so we can tailor your journeys",
      }));
      valid = false;
    }

    if (member?.email === values.email) {
      track("partnerdetail_duplicate_rejected", { rejectionsource: "email" });
      setError((prev) => ({
        ...prev,
        email: "Please use separate emails so we can tailor your journeys",
      }));
      valid = false;
    }

    return valid;
  };

  const onSubmit = () => {
    const isValid = validate();

    if (isValid) {
      const body: any = {
        primary_member: {
          email: member?.email,
          phone_number: member?.phone_number,
          uuid: member?.uuid,
        },
        partner: values,
      };

      addPartner({ memberId: member?.uuid || "", body });
      RitualAnalytics.track("invite_partner_complete", {
        ...eventPayload,
        partner: values,
      });
      updateOnboardingStatus({ partner_added: true });
      updateOnboardingStatusMutation({
        memberId: member?.uuid || "",
        body: {
          partner_added: true,
          partner_adding_skipped: false,
        },
      });
    }
  };
  const skipAddPartner = () => {
    RitualAnalytics.track("invite_partner_skip_click", eventPayload);
    updateOnboardingStatus({
      partner_added_skipped: true,
      partner_added: false,
    });
    updateOnboardingStatusMutation({
      memberId: member?.uuid || "",
      body: {
        partner_added: false,
        partner_adding_skipped: true,
      },
    });
  };

  return (
    <LayoutWithImage
      image={require(`src/assets/images/onboarding_image3${
        isMobile() ? "-mobile" : ""
      }.webp`)}
      title="Invite Your Partner"
      subtitle="An account will be created for your partner using the information you fill in. They will be invited to complete the account."
      position="top"
    >
      <Container>
        <Form>
          {fields.map((field) => (
            <Input
              {...field}
              error={error[field.id]}
              key={field.id}
              value={values[field.id]}
              onChange={(value: any) =>
                setValues((prev) => ({ ...prev, [field.id]: value }))
              }
            />
          ))}
        </Form>
        <div>
          <Button
            disabled={Object.values(values).some((value) => !value)}
            id={`onboarding_step-initial`}
            onClick={onSubmit}
          >
            Add Partner
          </Button>
          {(isMatchingCategory || addPartnerFromApp) && (
            <div>
              <SkipButton
                id="onboarding_skip-partner-added"
                onClick={skipAddPartner}
              >
                <Typography variant="button">Skip</Typography>
              </SkipButton>
              <Hint variant="body2">
                You'll be able to add your partner later.
              </Hint>
            </div>
          )}
        </div>
      </Container>
    </LayoutWithImage>
  );
};

export default AddPartnerStep;
