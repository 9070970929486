import { useGetScheduledSessionQueryResolver } from "src/api/resolvers";
import Loading from "src/components/Loading";
import InvalidSession from "./SessionStates/InvalidSession";
import { ErrorStatus } from "./SessionStates/InvalidSession/utils";
import NoScheduledSessions from "src/pages/JoinSession/SessionStates/NoScheduledSessions";
import SessionNotStarted from "src/pages/JoinSession/SessionStates/SessionNotStarted";

const JoinSession = () => {
  const {
    data: scheduledSession,
    isFetching,
    isError,
  } = useGetScheduledSessionQueryResolver();

  if (isFetching) {
    return <Loading />;
  }

  if (scheduledSession) {
    return <SessionNotStarted />;
  }

  if (!scheduledSession && !isError) {
    return <NoScheduledSessions />;
  }

  return <InvalidSession errorStatus={ErrorStatus.INVALID_LINK} />;
};

export default JoinSession;
