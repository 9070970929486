import InvalidLink from "src/assets/images/invalid-link.svg";

export enum ErrorStatus {
  INVALID_LINK = 1,
  CONNECTION_ISSUES = 2,
}

export const ErrorType = {
  [ErrorStatus.INVALID_LINK]: {
    slug: "invalid link",
    img: InvalidLink,
    title: "We Couldn’t Find Your Session",
    text:
      "Feel free to contact support for assistance in accessing \n" +
      "your session.",
  },
  [ErrorStatus.CONNECTION_ISSUES]: {
    slug: "connection issues",
    img: "",
    title: `Seems There's An Issue Connecting...`,
    text: "Please wait a moment and try again, or return to the app home page.",
  },
};
