import { useAuth0 } from "@auth0/auth0-react";
import { Close } from "src/assets/icons";
import { SessionPageLayoutProps } from "..";
import {
  CloseButton,
  Info,
  LogoutButton,
  LogoutButtonText,
  MemberName,
  MemberPicture,
  Wrapper,
} from "./styles";
import { ROUTES } from "src/types/routes";

const MobileInfo = ({
  memberInfo,
  onClick,
  ...props
}: SessionPageLayoutProps) => {
  const { logout } = useAuth0();

  return (
    <Wrapper {...props}>
      <CloseButton onClick={onClick} data-testid="close-button">
        <Close />
      </CloseButton>
      <Info>
        <MemberPicture src={memberInfo?.picture} alt={memberInfo?.full_name} />
        <MemberName>{memberInfo?.full_name}</MemberName>
      </Info>
      <LogoutButton
        variant="secondary"
        onClick={() =>
          logout({ returnTo: window.location.origin + ROUTES.JOIN_SESSION })
        }
        id="logout-button"
        data-testid="logout-button"
      >
        <LogoutButtonText>Log out</LogoutButtonText>
      </LogoutButton>
    </Wrapper>
  );
};

export default MobileInfo;
