import { GWPlatforms } from "src/api/types";

interface RouteObject {
  path: string;
  element: JSX.Element;
}
export interface RouteObjectExtended extends RouteObject {
  redirect?: string;
}

export const JOIN_SESSION_PREFIX = "session";
export const JOIN_SESSION_UNAUTHORIZED_PREFIX = "session-unauthorized";
export const JOIN_SESSION_REDIRECT_PREFIX = "session-unauthorized";

export enum ROUTES {
  HOME = "/",
  MODULE_SELECTION = "/select_pathway",
  LOGIN = "/login",
  QRCODE_LOGIN = "/qr_code_login",
  CHECK_EMAIL = "/check_email",
  SIGN_UP = "/signup",
  JOURNEY = "/library",
  PROFILE = "/profile",
  CONTACT_US = "/support",
  RITUAL_INFO = "/ritual_info",
  JOURNEY_STARTED = "/journey_started",
  VIDEO_TASK = "/video_task",
  AUDIO_TASK = "/audio_task",
  QUESTIONNAIRE_TASK = "/questionnaire_task",
  ALL_TASKS_COMPLETED = "/all_tasks_completed",
  DESKTOP_VIEW = "/desktop_view",
  PREPARING_PATHWAY = "/preparing_pathway",
  NOT_FOUND = "*",
  NO_ACCOUNT_FOUND = "/no_account_found",
  VIDEOASK = "/video_ask",
  TASK_COMPLETED = "/task_completed",
  MODULE_COMPLETED = "/pathway_completed",
  EXPERT_FEEDBACK = "/expert_feedback",
  CAROUSEL = "/carousel",
  PATHWAY_TEASER = "/pathway_teaser",
  ADD_PARTNER_FORM = "/add_partner_form",
  NO_ACTIVE_PLAN = "/no_active_plan",
  REACTIVATE_SUBSCRIPTION = "/reactivate_subscription",
  PLANS = "/plans",

  ONBOARDING_INITIAL = "/onboarding",
  ONBOARDING_BOOK_WELCOME_SESSION = "/onboarding/book-welcome-session",
  ONBOARDING_BOOK_MATCHING_SESSION = "/onboarding/book-matching-session",
  ONBOARDING_CREATE_ACCOUNT = "/onboarding/create-account",
  ONBOARDING_ADD_PARTNER = "/onboarding/add-partner",
  ONBOARDING_ALL_SET = "/onboarding/all-set",
  ONBOARDING_WELCOME_LOGIN = "/onboarding/login",

  CHAT = "/chat",
  CHAT_INITIAL = "/chat-initial",

  JOIN_SESSION = `/join-session`,
  JOIN_SESSION_LOGIN = `/join-session-unauthorized`,
  PRE_SESSION_SESSION_SURVEY = `/pre-session-survey`,

  SUBSCRIPTION = "/subscription",
  COMPLETE_PROFILE = "/complete_profile",

  JOURNAL_RESPONSE = "/journal_response",
  JOURNAL_PROMPT = "/journal_prompt",

  // App onboarding questionnaire
  PERSONALIZATION_DESCRIPTION = "/onboarding-questionnaire/personalization-description",
  CALCULATING_RESULTS = "/onboarding-questionnaire/calculating-results",

  // Deep links
  LOGIN_REDIRECT = "/router",
  SCHEDULE_POPUP = "/schedule",
}

export const onboardingSteps = [
  ROUTES.ONBOARDING_INITIAL,
  ROUTES.ONBOARDING_CREATE_ACCOUNT,
  ROUTES.ONBOARDING_BOOK_WELCOME_SESSION,
  ROUTES.ONBOARDING_BOOK_MATCHING_SESSION,
  ROUTES.ONBOARDING_ADD_PARTNER,
  ROUTES.ONBOARDING_WELCOME_LOGIN,
];

export const allowedOnDesktopRoutes = [
  ROUTES.HOME,
  ROUTES.LOGIN_REDIRECT,

  //Onboarding
  ROUTES.ONBOARDING_INITIAL,
  ROUTES.ONBOARDING_BOOK_WELCOME_SESSION,
  ROUTES.ONBOARDING_BOOK_MATCHING_SESSION,
  ROUTES.ONBOARDING_CREATE_ACCOUNT,
  ROUTES.ONBOARDING_ADD_PARTNER,
  ROUTES.ONBOARDING_ALL_SET,
  ROUTES.ONBOARDING_WELCOME_LOGIN,

  // Join Session
  ROUTES.JOIN_SESSION,
  ROUTES.JOIN_SESSION_LOGIN,
  JOIN_SESSION_PREFIX,
  ROUTES.PRE_SESSION_SESSION_SURVEY,
];

export const allowedWithoutMember = [JOIN_SESSION_PREFIX];

export const taskRoutesByPlatform = {
  [GWPlatforms.WISTIA]: ROUTES.VIDEO_TASK,
  [GWPlatforms.VIDEOASK]: ROUTES.VIDEOASK,
  [GWPlatforms.TYPEFORM]: ROUTES.QUESTIONNAIRE_TASK,
  [GWPlatforms.AUDIO]: ROUTES.AUDIO_TASK,
};
