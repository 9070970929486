import "./instrument";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { store } from "src/store";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";
import "src/assets/fonts/index.css";
import "./index.css";
import AblyProvider from "./contexts/AblyContext";
import * as Sentry from "@sentry/react";
import AppNotRespond from "src/components/ErrorScreens/AppNotRespond";
import NetworkStatusWrapper from "./components/NetworkStatusWrapper";
import { ROUTES } from "src/types/routes";

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const onRedirectCallback = (appState: AppState | undefined) => {
  if (appState?.transition_page) {
    window.location.replace(ROUTES.JOIN_SESSION);
  }
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        cacheLocation={
          window.location.origin.includes("localhost")
            ? "localstorage"
            : undefined
        }
        useRefreshTokens
        useCookiesForTransactions={true}
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
        onRedirectCallback={onRedirectCallback}
      >
        <Provider store={store}>
          <Router>
            <NetworkStatusWrapper>
              <CssBaseline />
              <Sentry.ErrorBoundary fallback={<AppNotRespond />}>
                <AblyProvider>
                  <App />
                </AblyProvider>
              </Sentry.ErrorBoundary>
            </NetworkStatusWrapper>
          </Router>
        </Provider>
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>,
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister().then(() => {
        window.location.reload();
      });
    })
    .catch((error) => {
      console.error("Error unregistering service worker:", error);
    });
}
