import React, { useEffect } from "react";
import {
  SessionSubText,
  SessionText,
  SessionTitle,
  SessionWrapper,
} from "src/pages/JoinSession/styles";
import illustration from "src/assets/images/presession-survey.svg";
import SessionPageLayout from "src/components/SessionPageLayout";
import { useGetMemberQuery } from "src/api/main";
import QRCode from "react-qr-code";
import { Wrapper } from "src/components/QRCode/styles";
import { SurveyLogo } from "src/entities/PreSessionSurvey/styles";
import useIsMobile from "src/hooks/useIsMobile";
import { SessionButton } from "src/pages/JoinSession/components/styles";
import ContactSupport from "src/entities/PreSessionSurvey/components/ContactSupport";
import { useGetPreSessionQueryResolver } from "src/api/resolvers";
import { useNavigate } from "react-router-dom";
import { PreSessionSurveyStatus } from "src/api/types";
import { ROUTES } from "src/types/routes";

const PreSessionSurvey = () => {
  const { data: member } = useGetMemberQuery();
  const { data: preSessionSurvey } = useGetPreSessionQueryResolver();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      preSessionSurvey &&
      preSessionSurvey.status === PreSessionSurveyStatus.COMPLETED
    ) {
      navigate(ROUTES.JOIN_SESSION);
    }
  }, [preSessionSurvey]);

  return (
    <SessionPageLayout memberInfo={member}>
      <SessionWrapper>
        <SurveyLogo src={illustration} alt="SurveyLogo" />
        <SessionTitle>Before your session</SessionTitle>
        <SessionText>
          {isMobile
            ? "Please answer 4 short questions for you and your expert to discuss and help track your progress towards your goals."
            : `We want to make sure your sessions help you work towards your goals. Please answer 4 short questions for your expert to review and for you to discuss in your session.`}
        </SessionText>
        {isMobile ? (
          <SessionButton
            id="join-session"
            onClick={() =>
              (window.location.href =
                process.env.REACT_APP_PRE_SESSION_SURVEY_LINK || "")
            }
          >
            Continue
          </SessionButton>
        ) : (
          <Wrapper>
            <QRCode
              value={process.env.REACT_APP_PRE_SESSION_SURVEY_LINK || ""}
              size={130}
            />
          </Wrapper>
        )}
        {!isMobile && (
          <SessionSubText>
            Access your pre-session questions by scanning the QR code.
          </SessionSubText>
        )}
      </SessionWrapper>
      <ContactSupport />
    </SessionPageLayout>
  );
};

export default PreSessionSurvey;
