import * as Sentry from "@sentry/react";

if (!window.location.origin.includes("localhost")) {
  Sentry.init({
    dsn: "https://8ec09d3b0329b2c78a1993a5b753775a@o4507695388098560.ingest.us.sentry.io/4507695586344960",
    integrations: [],
    beforeSend: function (event) {

      window.smartlook && window.smartlook('track', "Sentry error", {
        url: `${process.env.SENTRY_DOMAIN}/issues/?query=id%3A${event.event_id}`,
      });
  
      return event;
    },
    environment: process.env.REACT_APP_ENV,
  });

  window.Sentry = Sentry;
}
