import { useGetMemberQuery, useSessionShowEventMutation } from "src/api/main";
import {
  useGetExpertQueryResolver,
  useGetScheduledSessionQueryResolver,
} from "src/api/resolvers";
import RitualAnalytics from "client-analytics";

const useJoinSession = () => {
  const { data: scheduledSession } = useGetScheduledSessionQueryResolver();
  const [triggerSessionShow] = useSessionShowEventMutation();
  const { data: member } = useGetMemberQuery();
  const { data: expert } = useGetExpertQueryResolver();

  const joinSession = (sessionToJoin = scheduledSession) => {
    const redirectLink = sessionToJoin?.session_link || "";
    const sessionId = sessionToJoin?.next_session_event_id || "";

    if (sessionToJoin?.session_link) {
      const eventPayload = {
        member_uuid: member?.uuid,
        expert_id: expert?.uuid,
        expert_name: `${expert?.first_name} ${expert?.last_name}`,
      };

      RitualAnalytics.track("member_session_show", eventPayload);

      triggerSessionShow(sessionId).then(() => {
        window.location.href = redirectLink;
      });
    }
  };

  return { joinSession };
};

export default useJoinSession;
