import { useGetMemberQuery } from "src/api/main";
import Loading from "src/components/Loading";
import SessionPageLayout from "src/components/SessionPageLayout";
import {
  SessionLogo,
  SupportText,
  SupportUrl,
  SupportWrapper,
} from "../../styles";
import { SUPPORT_EMAIL } from "src/constants/support";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import {
  Button,
  Text,
  Title,
 Wrapper,
} from "./styles";
import CancelledIllustration from "src/assets/images/session-cancelled.svg";
import { isMobile } from "src/utils/isMobile";

const NoScheduledSessions = () => {
  const { data: member, isFetching } = useGetMemberQuery();
  const navigate = useNavigate();

  if (isFetching) {
    return <Loading />;
  }

  return (
    <SessionPageLayout memberInfo={member}>
      <Wrapper>
        <SessionLogo src={CancelledIllustration} alt="page_illustration" />
        <Title>No Sessions Scheduled</Title>
        <Text>
          Visit the app to schedule your next session.{" "}
        </Text>
        {isMobile() && (
          <Button
            id="schedule_new-session"
            onClick={() => navigate(ROUTES.HOME)}
          >
            Schedule new session
          </Button>
        )}
      </Wrapper>

      <SupportWrapper>
        <SupportText>Think this is a mistake?</SupportText>
        <SupportUrl onClick={() => (window.location.href = SUPPORT_EMAIL)}>
          Contact support
        </SupportUrl>
      </SupportWrapper>
    </SessionPageLayout>
  );
};

export default NoScheduledSessions;
