import React, { useEffect } from "react";
import QRCodeScreen from "src/components/QRCode";
import { OurRitualLogo } from "src/assets/icons";
import { Typography } from "src/components";
import theme from "src/theme";
import {
  Footer,
  PageWrapper,
  QRCodeBackground,
  Wrapper,
} from "src/pages/QRCodeLogin/styles";
import useGenerateShortLink from "src/hooks/useGenerateShortLink";
import LinkToEmailRequest from "src/components/QRCode/LinkToEmailRequest";
import Loading from "src/components/Loading";
import { useFlags } from "launchdarkly-react-client-sdk";
import RitualAnalytics from "client-analytics";
const illustration = require("src/assets/images/qrcode_background.png");

const QRCodeLogin = () => {
  const shortLink = useGenerateShortLink();
  const { showNativeAppQr } = useFlags();

  useEffect(() => {
    if (shortLink) {
      RitualAnalytics.track("member_QR_generated", {
        application: showNativeAppQr ? "native" : "webapp",
      });
    }
  }, [shortLink, showNativeAppQr]);

  if (!shortLink) return <Loading />;

  return (
    <PageWrapper>
      <Wrapper>
        <OurRitualLogo />
        <QRCodeBackground sx={{ backgroundImage: `url(${illustration})` }}>
          {shortLink && <QRCodeScreen url={shortLink} size={200} />}
        </QRCodeBackground>
        <Typography variant="headline">
          Here’s your{" "}
          <span style={{ color: theme.palette.primary.main }}>QR code</span>
        </Typography>
        <Typography
          variant="headline4"
          color={theme.palette.grey[600]}
          style={{ marginTop: "20px" }}
        >
          Scan the QR code above with your phone to access the app and log in on
          your mobile device.
        </Typography>
      </Wrapper>
      <Footer>
        <LinkToEmailRequest />
      </Footer>
    </PageWrapper>
  );
};

export default QRCodeLogin;
