import React, { useEffect, useState } from "react";
import {
  useGetMemberQuery,
  useLazyGetMemberStatusByEmailQuery,
  useRequestAccessLinkMutation,
} from "src/api/main";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginTypes, NoAccountFoundTypes } from "src/types/login";
import { ROUTES } from "src/types/routes";
import { MemberStatus } from "src/api/types";
import { parseInput } from "src/pages/DesktopView/helpers";
import {
  PhoneInputForm,
  PhoneInputWrapper,
  TextWrapper,
} from "src/pages/Onboarding/components/AllSetScreen/styles";
import {
  DesktopViewDescription,
  DesktopViewTitle,
} from "src/pages/Onboarding/components/AllSetScreen/Texts";
import { Button, Input, Typography } from "src/components";
import RitualAnalytics from "client-analytics";

const EmailLogin = () => {
  const { data: member } = useGetMemberQuery();
  const [email, setEmail] = useState("");
  const [
    getMemberStatusByEmail,
    { data: memberStatusByEmail, isError: isErrorFindingMemberByEmail },
  ] = useLazyGetMemberStatusByEmailQuery();
  const [requestAccessLink] = useRequestAccessLinkMutation();
  const navigate = useNavigate();
  const { state } = useLocation() as {
    state: { type: LoginTypes; email?: string };
  };

  useEffect(() => {
    if (isErrorFindingMemberByEmail) {
      navigate(ROUTES.NO_ACCOUNT_FOUND, {
        state: { type: NoAccountFoundTypes.EMAIL, email },
      });
    }

    RitualAnalytics.identify({
      id: memberStatusByEmail?.uuid || "",
      email: memberStatusByEmail?.email || "",
    });

    if (memberStatusByEmail?.status === MemberStatus.VERIFIED) {
      if (state?.type === LoginTypes.ACCESSLINK) {
        requestAccessLink({ uuid: memberStatusByEmail.uuid });
        navigate(ROUTES.CHECK_EMAIL);
      } else {
        navigate(ROUTES.ONBOARDING_WELCOME_LOGIN);
      }
    }

    redirectToNoActivePlanIfAccountDisabled(memberStatusByEmail?.status);
  }, [memberStatusByEmail, isErrorFindingMemberByEmail]);

  const redirectToNoActivePlanIfAccountDisabled = (status?: number) => {
    if (status === MemberStatus.DISABLED && process.env.REACT_APP_PRICING_URL) {
      navigate(ROUTES.NO_ACTIVE_PLAN);
    }
  };

  const onSubmitHandler = async () => {
    const parsedEmail = parseInput(email);
    getMemberStatusByEmail(parsedEmail);
  };

  useEffect(() => {
    if (!member) return;
    setEmail(member.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  useEffect(() => {
    if (state?.email) {
      setEmail(state?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TextWrapper>
        <DesktopViewTitle type={state?.type} />
        <DesktopViewDescription type={state?.type} />
      </TextWrapper>
      <PhoneInputWrapper>
        <PhoneInputForm>
          <Input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={setEmail}
          />
          <Button
            id="member_desktop-login_clicked"
            onClick={() => onSubmitHandler()}
          >
            {state?.type === LoginTypes.ACCESSLINK
              ? "Send link"
              : "Generate QR code"}
          </Button>
        </PhoneInputForm>
        {state?.type === LoginTypes.ACCESSLINK && (
          <Typography variant="body3" style={{ marginTop: 10 }}>
            Open the link from your mobile device in order to get access to the
            OurRitual app.
          </Typography>
        )}
      </PhoneInputWrapper>
    </>
  );
};

export default EmailLogin;
