import React, { useEffect, useState } from "react";
import { useGetMemberQuery } from "src/api/main";
import {
  useGetExpertQueryResolver,
  useGetPreSessionQueryResolver,
  useGetScheduledSessionQueryResolver,
} from "src/api/resolvers";
import illustration from "src/assets/images/zoom-session.svg";
import Loading from "src/components/Loading";
import SessionPageLayout from "src/components/SessionPageLayout";
import JoinSessionButton from "../../components/JoinSessionButton";
import {
  SessionLogo,
  SessionSubText,
  SessionText,
  SessionTitle,
  SessionWrapper,
} from "../../styles";
import Countdown from "./Countdown";
import {
  SESSION_START_GAP,
  formatSessionDate,
  getTimeUntilSession,
  isSessionInMoreThanAnHour,
} from "./utils";
import ContactSupport from "src/entities/PreSessionSurvey/components/ContactSupport";
import { PreSessionSurveyStatus } from "src/api/types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import dayjs from "dayjs";
import { useFlags } from "launchdarkly-react-client-sdk";

const SessionNotStarted = () => {
  const { data: member, isFetching } = useGetMemberQuery();
  const { data: expert } = useGetExpertQueryResolver();
  const { data: scheduledSession } = useGetScheduledSessionQueryResolver();
  const { data: preSessionSurvey } = useGetPreSessionQueryResolver();
  const [joinDisabled, setJoinDisabled] = useState(true);
  const moreThanHour = isSessionInMoreThanAnHour(
    scheduledSession?.next_session!,
  );
  const navigate = useNavigate();
  const { fitFeature } = useFlags();

  useEffect(() => {
    if (
      fitFeature &&
      preSessionSurvey &&
      scheduledSession &&
      preSessionSurvey.status === PreSessionSurveyStatus.UNCOMPLETED &&
      !dayjs().isAfter(dayjs(scheduledSession.next_session))
    ) {
      navigate(ROUTES.PRE_SESSION_SESSION_SURVEY);
    }
  }, [preSessionSurvey, scheduledSession]);

  useEffect(() => {
    if (!scheduledSession) return;

    const timeLeft =
      getTimeUntilSession(scheduledSession.next_session) - SESSION_START_GAP;

    const timeout = setTimeout(() => {
      setJoinDisabled(false);
    }, timeLeft);

    return () => {
      clearTimeout(timeout);
    };
  }, [scheduledSession]);

  if (isFetching) {
    return <Loading />;
  }

  const { sessionDate, sessionTime } = formatSessionDate(
    scheduledSession?.next_session!,
  );

  const getSessionTitle = (moreThanHour: boolean, joinDisabled: boolean) => {
    if (moreThanHour) return "You Still Have Time";
    if (joinDisabled) return "Almost Time For Your Session!";
    return "Time to join your session";
  };

  const getSessionText = (
    moreThanHour: boolean,
    joinDisabled: boolean,
    sessionTime: string,
    sessionDate: string,
    expertName?: string,
  ) => {
    if (moreThanHour)
      return `Your next session is scheduled for ${sessionTime} on ${sessionDate}.`;
    if (joinDisabled)
      return "Your video session with your expert is set to begin in";
    return `Your session with ${expertName} is about to start. Make sure you have a good internet connection and join.`;
  };

  return (
    <SessionPageLayout memberInfo={member}>
      <SessionWrapper>
        <SessionLogo src={illustration} alt="page_illustration" />
        <SessionTitle>
          {getSessionTitle(moreThanHour, joinDisabled)}
        </SessionTitle>
        <SessionText>
          {getSessionText(
            moreThanHour,
            joinDisabled,
            sessionTime,
            sessionDate,
            expert?.full_name,
          )}
        </SessionText>
        {!moreThanHour && joinDisabled && <Countdown />}
        <JoinSessionButton disabled={joinDisabled || moreThanHour} />
        {(moreThanHour || joinDisabled) && (
          <SessionSubText>
            You'll be able to join your session 5 minutes before your session
            start time using the button above
          </SessionSubText>
        )}
      </SessionWrapper>
      <ContactSupport />
    </SessionPageLayout>
  );
};

export default SessionNotStarted;
