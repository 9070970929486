import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Typography } from "src/components";
import {
  SessionContainer,
  SessionDuration,
  SkipButton,
  WelcomeWrapper,
  MatchingWrapper,
  WidgetWrapper,
  Wrapper,
  LabelWrapper,
} from "./styles";
import { Clock } from "src/assets/icons";
import theme from "src/theme";
import { useMediaQuery } from "@mui/material";
import useUpdateStatus from "../../utils/useUpdateStatus";
import { useGetOnboardingNextStep } from "../../utils/useOnboardingSteps";
import {
  useGetMemberQuery,
  useUpdateOnboardingStatusMutation,
} from "src/api/main";
import { useGetOnboardingStatusQueryResolver } from "src/api/resolvers";
import { useGetFirstSessionLink } from "src/utils/getFirstSessionLink";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import RitualAnalytics from "client-analytics";

const BookSession = () => {
  const mobileView = useMediaQuery(theme.breakpoints.down("lg"));
  const { data: onboardingStatus } = useGetOnboardingStatusQueryResolver();
  const updateOnboardingStatus = useUpdateStatus();
  const [updateOnboardingStatusMutation] = useUpdateOnboardingStatusMutation();
  const redirectToNextStep = useGetOnboardingNextStep();
  const { data: member } = useGetMemberQuery();

  const isMatchingPlan = useIsMatchingPlanCategory();
  const sessionType = isMatchingPlan ? "matching" : "welcome";
  const eventPayload = {
    uuid: member?.uuid,
    name: member?.full_name,
    email: member?.email,
    phone_number: member?.phone_number,
  };

  useEffect(() => {
    RitualAnalytics.track("account_verification_complete", eventPayload);

    if (!isMatchingPlan) {
      RitualAnalytics.track("welcome_session_schedule_start", eventPayload);
    }
  }, []);

  useEffect(() => {
    if (
      (sessionType === "welcome" &&
        (onboardingStatus?.welcome_session ||
          onboardingStatus?.welcome_session_skipped)) ||
      (sessionType === "matching" &&
        (onboardingStatus?.matching_session ||
          onboardingStatus?.matching_session_skipped))
    ) {
      redirectToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStatus]);

  useCalendlyEventListener({
    onEventScheduled: (payload) => {
      RitualAnalytics.track("welcome_session_schedule_complete", {
        ...eventPayload,
        scheduledTimestamp: payload.timeStamp,
      });

      updateOnboardingStatus({
        [`${sessionType}_session_skipped`]: false,
        [`${sessionType}_session`]: true,
      });

      updateOnboardingStatusMutation({
        memberId: member?.uuid || "",
        body: {
          first_session_scheduled: true,
          first_session_schedule_skipped: false,
        },
      });
    },
  });

  const skipSession = () => {
    RitualAnalytics.track("welcome_session_schedule_later_click", eventPayload);

    updateOnboardingStatus({
      [`${sessionType}_session_skipped`]: true,
      [`${sessionType}_session`]: false,
    });

    updateOnboardingStatusMutation({
      memberId: member?.uuid || "",
      body: {
        first_session_scheduled: false,
        first_session_schedule_skipped: true,
      },
    });
  };

  return (
    <Layout
      title="Book Your First Session"
      subtitle="Meet your relationship Expert and start building a plan for your relationship journey, including picking your areas of focus."
      LeftSide={
        <LeftSide onSkipClick={skipSession} sessionType={sessionType} />
      }
      RightSide={
        mobileView ? <></> : <SessionComponent sessionType={sessionType} />
      }
    />
  );
};

const LeftSide = ({
  onSkipClick,
  sessionType,
}: {
  onSkipClick: () => void;
  sessionType: "welcome" | "matching";
}) => {
  const mobileView = useMediaQuery(theme.breakpoints.down("lg"));
  const WrapperComponent =
    sessionType === "welcome" ? WelcomeWrapper : MatchingWrapper;

  return (
    <Wrapper>
      <LabelWrapper>
        <Typography variant="body-medium" color="#644F5D" weight={400}>
          Session length
        </Typography>
        <SessionDuration>
          <Clock />
          <Typography variant="body-medium" color="#31232F" weight={500}>
            30 min
          </Typography>
        </SessionDuration>
      </LabelWrapper>
      {mobileView && (
        <WrapperComponent>
          <SessionComponent sessionType={sessionType} />
        </WrapperComponent>
      )}
      <SkipButton
        id={`onboarding_skip-${sessionType}-session`}
        onClick={onSkipClick}
      >
        <Typography variant="button">Schedule Later</Typography>
      </SkipButton>
    </Wrapper>
  );
};

const SessionComponent = ({
  sessionType,
}: {
  sessionType: "welcome" | "matching";
}) => {
  const sessionLink = useGetFirstSessionLink(sessionType);
  
  // Add utm_source parameter to the sessionLink
  const calendlyUrl = sessionLink
    ? sessionLink + (sessionLink.includes("?") ? "&" : "?") + "utm_source=webapp"
    : "";

  const { data: member } = useGetMemberQuery();
  const [extended, setExtended] = useState(false);

  useCalendlyEventListener({
    onDateAndTimeSelected: () => setExtended(true),
    onEventTypeViewed: () => setExtended(false),
  });

  return (
    <SessionContainer>
      <WidgetWrapper
        sx={{
          ".calendly-inline-widget": {
            height: `${extended ? 955 : 630}px !important`,
            [theme.breakpoints.down("lg")]: {
              height: `${extended ? 1050 : 600}px !important`,
            },
          },
        }}
      >
        <InlineWidget
          url={calendlyUrl}
          pageSettings={{ hideEventTypeDetails: true }}
          prefill={{
            name: `${member?.first_name} ${member?.last_name}`,
            email: member?.email,
          }}
        />
      </WidgetWrapper>
    </SessionContainer>
  );
};

export default BookSession;
